﻿<template>
  <div>
    <Headline
      title="Viaje por viaje"
      subtitle="Concéntrese en el objetivo de su viaje.<br>¡Le aseguramos el éxito!"
      image="index"
      variant="featured"
    >
      <Button @click="goToContent" variant="tertiary" size="lg">Viaja con nosotros</Button>
    </Headline>
    <Section
      id="quienes-somos"
      title="Quiénes somos"
      image="index-1"
    >
      <Card
        title="#1 Wamos - Grupo Turístico Nº1 en España e Portugal"
      ></Card>
      <Card
        title="+50 años mejorando procesos de gestión de viajes de empresas"
      ></Card>
      <Card
        title="96% de retención de clientes"
      ></Card>
      <Card
        title="24/7 servicio al cliente"
      ></Card>
      <Card
        title="+20 años de certificación de calidad"
      ></Card>
      <Card
        title="Representante exclusivo One Global Travel para España e Portugal"
        href="https://oneglobaltravel.com/"
      ></Card>
    </Section>
    <Section
      title="Nuestros servicios"
      image="index-2"
    >
      <!-- <Card
        title="Vacacional"
        image="index-1"
        href="https://topatlantico.es/"
      ></Card> -->
      <Card
        title="Reuniones y Eventos"
        image="index-2"
        to="/servicios/reuniones-y-eventos"
      ></Card>
      <Card
        title="Viajes de grupos y Incentivos"
        image="index-3"
        to="/servicios/grupos-y-incentivos"
      ></Card>
      <Card
        title="Viajes corporativos"
        image="index-4"
        to="/servicios/viajes-corporativos"
      ></Card>
    </Section>
    <Section
      title="Productos"
      image="index-3"
      variant="dark"
    >
      <Card
        title="Reservas online"
        image="index-5"
        to="/productos/reservas-online"
      ></Card>
      <Card
        title="Report"
        image="index-6"
        to="/productos/report"
      ></Card>
      <Card
        title="Facturación electrónica"
        image="index-7"
        to="/productos/facturacion-electronica"
      ></Card>
      <Card
        title="Integración de sistemas"
        image="index-8"
        to="/productos/integracion-de-sistemas"
      ></Card>
    </Section>
    <div class="grid grid--no-gap">
      <iframe class="map" loading="lazy" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=TRAVELPORT, Lisboa, Portugal&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
      <ContactForm />
    </div>
  </div>
</template>
<script setup>
definePageMeta({
  title: 'Conócenos',
})
const title = useRoute().meta.title
useHead({
  title: `${title} | Geotur`,
})

const goToContent = () => {
  document.getElementById('quienes-somos').scrollIntoView({
    behavior: 'smooth',
  })
}
</script>
<style scoped>
.map {
  width: 100%;
  min-height: 30rem;
}
</style>